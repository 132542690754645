<template>
  <div>
    <div class="Solution_page">
      <div class="banner_wrap">
        <p class="title fadeInLeft slow animated wow">
          建筑信息化全栈式解决方案，助力企业数字化转型
        </p>
        <p class="title_m fadeInLeft slow animated wow">
          建筑信息化全栈式解决方案
        </p>
        <p class="title_m fadeInLeft slow animated wow">助力企业数字化转型</p>
        <p class="des fadeInRight slow animated wow">
          为建筑企业提供三维扫描硬件，建模、测量、数据智能分析软件和云服务的全栈式产品和服务
        </p>
      </div>

      <div class="des_wrap">
        <div class="des_content">
          <div class="item" v-for="(item, index) in list" :key="index">
            <img
              class="left fadeInLeft slow animated wow"
              :src="item.img"
              alt=""
            />
            <div class="right fadeInRight slow animated wow">
              <div class="title">{{ item.title }}</div>
              <p class="des" style="margin-bottom: 0.13rem">{{ item.des }}</p>
              <div class="btn" @click="showMore(item.path)">了解更多</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  name: "UCL360",
  data() {
    return {
      isMobile: false,
      list: [
        {
          title: "自动化实测实量解决方案",
          des: "覆盖建筑室内和外立面全生命周期，形成结构化数据，通过对建筑图纸矢量化和自研三维激光扫描仪逆向建模的自动拟合，完美实现工程管理实测实量、BIM数据应用的操作可视化、数据分析结构化、测量自动化、正逆向数据比对。",
          img: require("@/assets/solution_1.png"),
          path: "/#/Info?id=23",
        },
        {
          title: "自动化分户验收解决方案",
          des: "面向质监站、甲方、总包、验房公司，是人工测量效率的8-9倍；可将户型数据自动生成分户验收表、户型图自动拟合实测数据，提供建立一户一档以及相应二维码的自动化解决方案。",
          img: require("@/assets/solution_2.png"),
          path: "/#/Info?id=29",
        },
        {
          title: "数字化装修解决方案",
          des: "基于人机云协同、构件识别、自动化精准拼接，自动化逆向建模，将深化辅助设计与施工放线坐标系统一，保障装修顺利开展及交付验收，赋能装修企业数字化转型。",
          img: require("@/assets/solution_3.png"),
          path: "/#/Info?id=33",
        },
        {
          title: "数字孪生解决方案",
          des: "通过三维激光扫描仪对空间进行扫描、计算、渲染自动生成逆向模型，模型可为设计师提供准确的旧改空间结构化数据信息。BIM语义化模型协同各类传感器可用于监测和分析建筑物的性能、健康状况和潜在问题，从而提高维护和管理效率。",
          img: require("@/assets/solution_6.png"),
          path: "/#/digitalTwin",
        },
        {
          title: "隧道桥梁行业解决方案",
          des: "为隧道路桥在隧道提供掘进施工、道路铺设、桥梁施工提供土方算量、正逆向的BIM设计对比方向指引、结构化数据测量、装配式检测；在运维期提供安全运维全栈式解决方案。",
          img: require("@/assets/solution_4.png"),
          path: "",
        },
        {
          title: "智能建造教育行业解决方案",
          des: "校企合作共同培养智能建造人才，提供从测量到自动化、智能化的研究培训资料，助力学校完善教学系统。",
          img: require("@/assets/solution_5.png"),
          path: "/#/Info?id=32",
        },
      ],
    };
  },
  components: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
  methods: {
    showMore(data) {
      window.location.href = data;
    },
  },
};
</script>

<style lang="less">
.Solution_page {
  background: #fff;
  overflow: hidden;
  .banner_wrap {
    background-image: url(../assets/solution_bg.png);
    background-size: cover;
    width: 10rem;
    height: 2.88rem;
    padding-top: 0.96rem;
    .title {
      display: block;
      margin-bottom: 0.1rem;
      color: #fcfcfc;
      font-size: 0.21rem;
    }
    .title_m {
      display: none;
    }
    .des {
      color: #e6e6e6;
      font-size: 0.13rem;
    }
  }

  .des_wrap {
    padding: 0 1.05rem 0.32rem;

    .des_content {
      width: 100%;
      .item {
        padding: 0.19rem 0.34rem;
        background-color: #edf1f7;

        display: flex;
        justify-content: space-between;
        margin-bottom: 0.29rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .left {
        width: 3.46rem;
      }
      .right {
        width: 3.4rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .btn {
          width: 0.8rem;
          height: 0.26rem;
          background: #02aeec;
          border-radius: 0.02rem 0.02rem 0.02rem 0.02rem;
          text-align: center;
          cursor: pointer;
          color: #fcfcfc;
          line-height: 0.26rem;
          font-size: 0.08rem;
          &:hover {
            opacity: 0.7;
          }
        }
        .title {
          border-left: 0.02rem solid #02aeec;
          height: 0.17rem;
          margin-bottom: 0.15rem;
          margin-top: 0.25rem;
          line-height: 0.17rem;
          padding-left: 0.06rem;
          color: #333333;
          font-weight: 600;
          font-size: 0.15rem;
        }

        .des {
          line-height: 0.16rem;
          color: #666666;
          font-size: 0.084rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .Solution_page {
    .breadcrumb_wrap {
      display: none;
    }
    .banner_wrap {
      background-image: url(../assets/solution_m_bg.png);
      background-size: cover;
      width: 10rem;
      height: 16.79rem;
      padding-top: 6.31rem;
      .title {
        display: none;
      }
      .title_m {
        display: block;
        margin-bottom: 0.28rem;
        color: #fcfcfc;
        font-size: 0.43rem;
        &:last-child {
          margin-bottom: 0.38rem;
        }
      }
      .des {
        color: #e6e6e6;
        width: 9rem;
        font-size: 0.32rem;
        text-align: center;
        margin: 0 auto;
      }
    }

    .des_wrap {
      padding: 0.61rem 0.43rem;
      background: #fff;
      .des_content {
        width: 100%;
        .item {
          padding: 0.55rem 0.34rem;
          background: #edf1f7;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.29rem;
        }
        .left {
          width: 100%;
          margin-bottom: 0.43rem;
        }
        .right {
          width: 100%;
          text-align: left;
          .btn {
            width: 2.03rem;

            font-size: 0.29rem;
            height: 0.71rem;
            background: #02aeec;
            border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
            line-height: 0.71rem;
            color: #fff;
          }
          .title {
            border-left: 0.07rem solid #02aeec;
            height: 0.63rem;
            margin-bottom: 0.4rem;
            line-height: 0.63rem;
            padding-left: 0.06rem;
            color: #333333;
            font-weight: 600;

            font-size: 0.43rem;
          }

          .des {
            line-height: 0.48rem;
            color: #666666;

            font-size: 0.32rem;
          }
        }
      }
    }
  }
}
</style>
